import request from '@/apis/axios'
import { IResponse } from '@/apis/axios/config'
import type {
  PushUseInfoParam,
  PushUseInfoResult,
  SetupPrivacyInfoParam,
  SetupPrivacyInfoResult,
  SetupTermsInfoParam,
  SetupTermsInfoResult,
  UpdatePushUseInfoParam,
  UpdatePushUseInfoResult,
} from '~/apis/setting/types'

/**
 * 2.4.1 이용약관 조회
 */
export const setupTermsInfo = (
  params: SetupTermsInfoParam
): Promise<IResponse<SetupTermsInfoResult>> => {
  return request.get({ url: '/api/setup/setupTermsInfo', params })
}

export const setupPrivacyInfo = (
  params: SetupPrivacyInfoParam
): Promise<IResponse<SetupPrivacyInfoResult>> => {
  return request.get({ url: '/api/setup/setupPrivacyInfo', params })
}

/*
2.5.4 토픽별 알림 사용 설정 조회
* */
export const pushUseInfo = (
  params: PushUseInfoParam
): Promise<IResponse<PushUseInfoResult>> => {
  return request.get({ url: '/api/push/pushUseInfo', params })
}

/*
2.5.5. 토픽별 알림 사용 설정 처리
* */
export const updatePushUseInfo = (
  params: UpdatePushUseInfoParam
): Promise<IResponse<UpdatePushUseInfoResult>> => {
  return request.post({ url: '/api/push/pushUseInfo', params })
}
